@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap";
* {
  box-sizing: border-box;
}

html {
  width: 100%;
  min-width: 320px;
  height: 100%;
  font-size: 12px;
}

@media screen and (width >= 540px) {
  html {
    font-size: 16px;
  }
}

:before, :after {
  box-sizing: inherit;
}

body {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "YTLC" 500;
  color: #fff;
  background-color: #000;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  height: 100%;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
  display: flex;
}

body.noscroll {
  overflow: hidden;
}

h1, h2, h3 {
  margin-top: 0;
}

p {
  margin: 0;
}

a, a:visited, a:active, a:hover {
  text-decoration: none;
}

button, .button {
  cursor: pointer;
  color: #000;
  background: #e3e2e2;
  border: 2px solid #8d8888;
  border-radius: 5px;
  margin: 6px 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 200;
  box-shadow: 0 3px 8px #b3b3b33d;
}

x button:hover, x .button:hover {
  font-weight: 400;
  box-shadow: 0 3px 8px #b3b3b3;
}

button:active, .button:active {
  box-shadow: none;
}

button:focus, .button:focus {
  outline: none;
}

input, .input, textarea {
  color: #000;
  background: #fff;
  border: 2px solid #8d8888;
  border-radius: 5px;
  margin: 6px 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 200;
}

header {
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 5rem;
  padding: 1rem 2rem;
  display: flex;
}

header h1 {
  color: #fff;
  z-index: 5;
  margin: 0;
  font-size: 5rem;
  font-weight: 500;
}

.hero {
  color: #000;
  background-image: url("clouds.05f92d5c.jpg");
  background-size: cover;
  width: 100%;
  padding: 60px 20px;
  font-size: 4rem;
  font-weight: 700;
  line-height: 110%;
}

section {
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  display: flex;
}

.cta {
  background-color: #f0f0f0;
  width: 100%;
  padding: 60px 20px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 130%;
}

section {
  color: #000;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  display: flex;
}

section p {
  margin-bottom: 20px;
}

.formContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#emailForm {
  flex-direction: column;
  width: 80%;
  max-width: 540px;
  display: flex;
}

.terms {
  font-size: .8rem;
  line-height: 110%;
}

.thank-you-message {
  color: #000;
  margin-top: 15px;
  font-size: 18px;
  display: none;
}

footer {
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  display: flex;
}
/*# sourceMappingURL=index.ac213c01.css.map */
