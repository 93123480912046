.hero {
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  line-height: 110%;
  padding: 60px 20px;

  background-size: cover;
  background-image: url('../assets/clouds.jpg');

  color: black;
}

section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  p {
    margin-bottom: 20px;
  }
}
