footer {
  width: 100%;
  height: 100%;

  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}
