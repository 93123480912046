header {
  width: 100%;
  height: 5rem;

  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;

  h1 {
    font-size: 5rem;
    font-weight: 500;
    color: #ffffff;
    z-index: 5;
    margin: 0;
  }
}
