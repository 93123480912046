.cta {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 130%;
  padding: 60px 20px;

  background-color: #f0f0f0;
}

section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  color: #000000;

  p {
    margin-bottom: 20px;
  }
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#emailForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 540px;
}

.terms {
  font-size: 0.8rem;
  line-height: 110%;
}

.thank-you-message {
  display: none;
  color: #000000;
  font-size: 18px;
  margin-top: 15px;
}
