@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  min-width: 320px;

  font-size: 12px;

  @media screen and (min-width: 540px) {
    font-size: 16px;
  }
}

*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  min-width: 320px;

  display: flex;
  flex-direction: column;

  background-color: #000000;

  margin: 0;
  padding: 0;

  font-family: 'Nunito Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: 'wdth' 100, 'YTLC' 500;

  color: #ffffff;
  line-height: 1.3;

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3 {
  margin-top: 0;
}

p {
  margin: 0;
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
}

// Buttons
button,
.button {
  padding: 0.5rem 1rem;
  background: rgb(227, 226, 226);
  border: 2px solid #8d8888;
  border-radius: 5px;
  cursor: pointer;
  color: #000000;
  font-size: 1rem;
  font-weight: 200;
  box-shadow: rgba(179, 179, 179, 0.24) 0px 3px 8px;
  margin: 6px 0;
  x &:hover {
    font-weight: 400;
    box-shadow: rgba(179, 179, 179, 1) 0px 3px 8px;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}

input,
.input,
textarea {
  padding: 0.5rem 1rem;
  background: rgb(255, 255, 255);
  border: 2px solid #8d8888;
  border-radius: 5px;
  color: #000000;
  font-size: 1rem;
  font-weight: 200;
  margin: 6px 0;
}
